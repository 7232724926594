import React from 'react';

function Button(props) {
    return (
        <div className={`action-button ${props.buttonType}`} onClick={props.onClick}>
            <p>{props.title}</p>
        </div>
    );
}

export default Button;
