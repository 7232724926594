import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
import './App.scss'
import SideNav from './components/sidenav/SideNav'
import Dashboard from './routes/Dashboard'
import styled from '@emotion/styled'
import LoadingScreen from './components/Loaders/LoadingScreen'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import Home from "./routes/Home";
import MobileHeader from "./components/header/MobileHeader";
import Applications from "./routes/Applications";
import {APP_BASE_URL} from "./Constants";

const AppContainer = styled('div')`a, p, h1, h2, h3, h4, h5 {color: ${props => props.theme.font};}
background-color: ${props => props.theme.dashBoard};
body{
		background-color: ${props => props.theme.dashBoard};
}`;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            apps: [],
            appLoaded: false,
            currentData: [],
            currentID: 0,
            loadAnimation: false,
            lastFetch: "",
            services: []
        };
        this.fetchNodes();
        this.fetchApps();
    }

    updateData = (id) => {
        this.setState({currentID: id, currentData: this.state.data[id]})
    };

    updateHeader = (url) => {
        this.setState({url: url})
    };

    fetchApps = () => {
        const self = this;
        fetch(`${APP_BASE_URL}/uptime/all`).then(res => res.json()).then(res => {
            self.setState({
                apps: res,
                lastFetch: new Date(Date.now()).toLocaleString(),
                loadAnimation: true
            });
            // console.log(res);
        }).catch(err => console.log(err));
    };


    fetchNodes = () => {
        const self = this;
        fetch(`${APP_BASE_URL}/uptime/services`).then(res => res.json()).then(res => {
            self.setState({
                data: res,
                appLoaded: true,
                lastFetch: new Date(Date.now()).toLocaleString(),
                loadAnimation: true
            });
            console.log(res);
        }).catch(err => console.log(err));
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            this.fetchNodes();
            this.fetchApps();
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        const {data, apps, appLoaded, currentData, currentID, loadAnimation, lastFetch} = this.state;
        return (
            <AppContainer className="App">
                <TransitionGroup component={null}>
                    {appLoaded ? (
                        <CSSTransition
                            in={appLoaded}
                            key="content"
                            timeout={700}>
                            <div className="content">
                                <div className="side-nav-outer" style={{position: 'relative'}}>
                                    <SideNav date={lastFetch}/>
                                </div>
                                <MobileHeader date={lastFetch}
                                              updateData={this.updateData} currentID={currentID}
                                              currentData={currentData} data={data}/>
                                <Switch>
                                    <Route path="/" exact render={(props) => <Home {...props} data={data}/>}/>
                                    <Route path="/all" exact
                                           render={(props) => <Applications {...props} apps={apps}/>}/>
                                    <Route path="/dashboard" exact
                                           render={(props) => <Dashboard {...props}
                                                                         data={data}
                                                                         loadAnimation={loadAnimation}
                                                                         updateData={this.updateData}
                                                                         currentID={currentID}
                                                                         currentData={currentData}/>}/>
                                    {/*<Route component={NoMatch}/>*/}
                                </Switch>
                            </div>
                        </CSSTransition>
                    ) : (
                        <CSSTransition
                            in={!appLoaded}
                            classNames="loading-screen"
                            timeout={700}
                            key="splash-screen"
                            exit>
                            <div>
                                <LoadingScreen/>
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </AppContainer>
        )
    }
}

export default App
