import React from 'react'
import styled from '@emotion/styled'

const HeaderBar = styled('div')`background-color: ${props => props.theme.header};`;

const Header = (props) => {
	const switchDashboard = (data, id) => {
		props.updateData(id);
	};

	return (
		<HeaderBar className="header-container">
			<div className="header-dashboard-information-wrapper">
				<div className="header-dashboard-title">
					<h2>Dashboard</h2>
					<div className="header-dashboard-information">
						<select onChange={(e) => {
							switchDashboard(props.data, e.target.value)
						}} className="styled-select">
							{props.data.map((data, index) => {
								return <option value={index} key={data.serviceName}>{data.serviceName}</option>
							})}
						</select>
						<div
							className={`dashboard-information-status ${props.currentData.online ? "online" : "offline"}`}>
							<p>{props.currentData.online ? "Online" : "Offline"}</p>
						</div>
					</div>
				</div>
				<div className="header-dashboard-id">
					<h3>
						{props.currentData.serviceName}
					</h3>
					<h5>{props.currentData.macAddress}</h5>
				</div>
			</div>
		</HeaderBar>
	)
};

export default Header
