import React from 'react'

const Apps = (props) => {
	console.log(props.apps);
	return (
		<div className="application-card-container">
			{props.apps.map((app, index) => {
				return <div className="application-card">
					<div>{app.applicationName}</div>
					<div>{app.services.length}</div>
					<div>{app.online ? "online" : "offline"}</div>
					<div>{app.companyName}</div>
				</div>

			})}

		</div>
	)
};
export default Apps
