import React, {useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import TransitionGroup from "react-transition-group/cjs/TransitionGroup";
import CSSTransition from "react-transition-group/cjs/CSSTransition";
import styled from "@emotion/styled";
import Button from "../components/general/Button";
import {formatBytes} from "../components/global/Functions";
import {APP_BASE_URL} from "../Constants";

const ListContainer = styled('div')`background-color: ${props => props.theme.listColor};
-webkit-box-shadow:${props => props.theme.shadow}`;

const ListTabs = styled('div')`background-color:  ${props => props.theme.listTabs};
    li 
    {
        &:hover
        {
            background-color:${props => props.theme.listColor};
            cursor:pointer;
        }
        &.active {background-color: ${props => props.theme.listColor};}
    }
`;

const ListHeader = styled('div')`background-color: ${props => props.theme.listHeader};`;

const SearchBox = styled('div')`border: ${props => props.theme.searchBoxBorder}; 
        background-color:${props => props.theme.searchBox};
        svg{path{fill:${props => props.theme.font};}}
        input 
        {
           color:${props => props.theme.font};
           background-color:${props => props.theme.searchBox};
        }
`;

const ListWrapper = styled('div')`background-color: ${props => props.theme.listWrapper};
border-color:${props => props.theme.listBorder}`;

const Home = (props) => {
	const [showActiveDashboards, setOnlineDashboards] = useState(true);
	const [activeIndex, setActiveIndex] = useState(null);
	const [setValue] = useState('');
	const TabBar = useRef();

	const [currentValues, setValues] = useState();
	const prevValuesRef = useRef();

	useEffect(() => {
		setValues(props.data);
		prevValuesRef.current = currentValues;
	});


	const checkValue = (data, id, name, obj, theme) => {
		if (typeof data !== "undefined") {
			for (let x of data) {
				if (x.macAddress === id) {

					if (x[name] > obj) {
						console.log(x[name]);
						return `positive-${theme}`
					}
				}
			}
		}
	};

	const activeDashboards = () => {
		setOnlineDashboards(true);
		// close list if navigating to different section
		if (!showActiveDashboards) {
			setActiveIndex(null)
		}
	};

	const inActiveDashboards = () => {
		setOnlineDashboards(false);
		// close list if navigating to different section
		if (showActiveDashboards) {
			setActiveIndex(null);
		}
	};

	const handleText = (input) => {
		setValue(input.target.value)
	};

	const changeActivity = (applicationName, serviceName, response) => {
		fetch(`${APP_BASE_URL}/uptime/service/${applicationName}/${serviceName}/activate`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({active: response}),
		}).then(function (response) {
			if (!response.ok) {
				console.log('error', response);
				throw new Error(response.statusText)
			}
			setActiveIndex(null);
		}).catch(error => console.log(error))
	};


	return (
		<div className="home-container">
			<ListContainer className="dashboard-list-container">
				<ListTabs className="dashboard-list-tabs">
					<li onClick={activeDashboards} className={showActiveDashboards ? 'active left' : 'left'}>
						<p>Active</p></li>
					<li onClick={inActiveDashboards} className={showActiveDashboards ? null : 'active'}><p>Inactive</p>
					</li>
				</ListTabs>
				<div className="dashboard-inner-wrapper">
					<div className="dashboard-list-search-container">
						<SearchBox className="dashboard-list-search">
							<input type="text" onChange={(e) => {
								handleText(e)
							}} placeholder="Search..."
							       onFocus={(e) => e.target.placeholder = ''}
							       onBlur={(e) => e.target.placeholder = 'Search...'}
							/>
							<FontAwesomeIcon icon={faSearch}/>
						</SearchBox>
					</div>
					<div className="dashboard-list-body">
						<ListHeader ref={TabBar} className="dashboard-list-header">

							<span className="dashboard-list-cell"><p>Status </p></span>

							<span className="dashboard-list-cell"><p>Device </p></span>

							<span className="dashboard-list-cell"><p>Uptime</p></span>
							<span className="dashboard-list-cell"><p>Restarts</p></span>

							<span className="dashboard-list-cell"><p>Company</p></span>
							<span className="dashboard-list-cell"><p>Application </p></span>
							<span className="dashboard-list-cell"><p>Service </p></span>

							<span className="dashboard-list-cell"><p>Ram</p></span>
							<span className="dashboard-list-cell"><p>(%)</p></span>
							<span className="dashboard-list-cell"><p>Cores</p></span>
							<span className="dashboard-list-cell"><p>Ping</p></span>
							<span className="dashboard-list-cell"><p>OS</p></span>

							<span className="dashboard-list-cell"><p>Updated</p></span>
							<span className="dashboard-list-cell"><p>Last Restart</p></span>
							<span className="dashboard-list-cell"><p>Registered</p></span>
						</ListHeader>
						<TransitionGroup component={null}>
							{props.data.map((data, index) => {
								return (
									<CSSTransition timeout={700} classNames="fade-in-list" key={data.macAddress}>
										<ListWrapper
											className={`dashboard-list-wrapper ${activeIndex === index
												? 'open'
												: null}`}>
											<div className="dashboard-list-inner-wrapper">
												<div className="dashboard-list-cell">
													<p className={data.online ? 'online' : 'offline'}>{data.online
														? 'ONLINE'
														: <a onClick={()=> changeActivity(data.applicationName, data.serviceName,false)}>'OFFLINE'</a>}
													</p>
												</div>

												<div className="dashboard-list-cell">
													<p>{data.node.deviceName}</p>
												</div>

												<div className="dashboard-list-cell">
													<p>{data.uptime} ({Math.round(data.upTicks / (data.upTicks + data.downTicks) * 100)}%) </p>
												</div>

												<div className="dashboard-list-cell">
													<p>{data.restarts} </p>
												</div>

												<div className="dashboard-list-cell">
													<p>{data.companyName}</p>
												</div>

												<div className="dashboard-list-cell">
													<p>{data.applicationName}</p>
												</div>

												<div className="dashboard-list-cell">
													<p>{data.serviceName}</p>
												</div>


												<div className="dashboard-list-cell">
													<p>{formatBytes(data.node.usedRam)} / <br/>{formatBytes(data.node.totalRam)}</p>

												</div>
												<div className="dashboard-list-cell">
													<p key={data.usedRam}
													   className={`animated-text ${checkValue(currentValues, data.macAddress, "usedRam", data.usedRam, "light")}`}>
														{(data.node.usedRam / data.node.totalRam * 100).toFixed(2)}%
													</p>
												</div>
												<div className="dashboard-list-cell">
													<p>{data.node.coreCount} </p>
												</div>

												<div className="dashboard-list-cell">
													<p>{data.ping} </p>
												</div>

												<div className="dashboard-list-cell">
													<p>{data.node.os} </p>
												</div>

												<div className="dashboard-list-cell">
													<p>{data.updated} </p>
												</div>
												<div className="dashboard-list-cell">
													<p>{data.lastRestart} </p>
												</div>
												<div className="dashboard-list-cell">
													<p>{data.registered} </p>
												</div>

											</div>
											{
												showActiveDashboards ? (
														<div className="list-action">
															<Button
																buttonType="caution"
																onClick={() => {
																	changeActivity(data.macAddress, false)
																}}
																title="Set Inactive"/>
														</div>
													) :
													(
														<div className="list-action">
															<Button
																buttonType="positive"
																onClick={() => {
																	changeActivity(data.macAddress, true)
																}}
																title="Set active"/>
														</div>
													)
											}
										</ListWrapper>
										{/*<List data={data} openList={openList} index={index} showActiveDashboards={showActiveDashboards} changeActivity={changeActivity}/>*/}
									</CSSTransition>
								)
							})}
						</TransitionGroup>
					</div>
				</div>
			</ListContainer>
		</div>
	)
};
export default Home
