import * as React from 'react';
import ServerDetails from "./cards/ServerDetails";
import DiskDetails from "./cards/DiskDetails";
import RamDetails from "./cards/RamDetails";
import CoreDetails from "./cards/CoreDetails";
import ClockDetails from "./cards/ClockDetails";
import UptimeDetails from "./cards/UptimeDetails";
import CpuDetails from "./cards/CpuDetails";
import OsDetails from "./cards/OsDetails";
import PingDetails from "./cards/PingDetails";

export const MobileDashboard = (props) => {
    return (
        <React.Fragment>
            {/*<RamDetails delay={150} name={props.loadAnimation ? `ram pop-animation ` : `ram`} data={props.currentData}/>*/}
            {/*<div className="ipad">*/}
            {/*    <CoreDetails delay={200} name={props.loadAnimation ? `cores pop-animation ` : `cores`} data={props.currentData}/>*/}
            {/*    <ClockDetails delay={250} name={props.loadAnimation ? `clock-speed pop-animation ` : `clock-speed`}*/}
            {/*                  data={props.currentData}/>*/}
            {/*</div>*/}
            {/*<ServerDetails delay={50} name={props.loadAnimation ? `server pop-animation ` : `server`} data={props.currentData}/>*/}
            {/*<DiskDetails delay={100} name={props.loadAnimation ? `disk pop-animation ` : `disk`} data={props.currentData}/>*/}
            {/*<UptimeDetails delay={300} name={props.loadAnimation ? `uptime pop-animation ` : `uptime`} data={props.currentData}/>*/}
            {/*<CpuDetails delay={350} name={props.loadAnimation ? `cpu-load pop-animation ` : `cpu-load`} data={props.currentData}/>*/}
            {/*<OsDetails delay={400} name={props.loadAnimation ? `os pop-animation ` : `os`} data={props.currentData}/>*/}
            {/*<PingDetails delay={450} name={props.loadAnimation ? `ping pop-animation ` : `ping`} data={props.currentData}/>*/}
            <RamDetails  data={props.currentData}/>
            <CoreDetails  data={props.currentData}/>
            <ClockDetails
                          data={props.currentData}/>
            <ServerDetails data={props.currentData}/>
            <DiskDetails  data={props.currentData}/>
            <UptimeDetails  data={props.currentData}/>
            <CpuDetails  data={props.currentData}/>
            <OsDetails data={props.currentData}/>
            <PingDetails data={props.currentData}/>
        </React.Fragment>
    );
};